import './styled.css';
import React, { useState, useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';

import PixImg from '../../assets/images/pix.png';
import CartoesImg from '../../assets/images/cartoes.png';
import Loading from '../../assets/images/loading.gif';

import Header from '../../components/header';
import { Location } from '../../components/location';
import { Attendee } from '../../components/attendee';
import { Hotel } from '../../components/hotel';
import { Flight } from '../../components/flight';
import { GeneralInfo } from '../../components/generalinfo';

import { Token } from '../../components/token';
import { Customer } from '../../components/customer';
import { Pix } from '../../components/pix';
import { Modal } from '../../components/modal';
import { WarningAdBlock } from '../../components/warningadblock';

import { TransactionContext } from '../../contexts/TransactionContext';

import { useError } from '../../hooks/useError';
import { useModelDefault } from '../../hooks/useModelDefault';
import { useList } from '../../hooks/useList';
import { useMask } from '../../hooks/useMask';
import { usePaymentOption } from '../../hooks/usePaymentOption';
import { useVar } from '../../hooks/useVar';

import { transactions, created } from '../../utils/service/payment';
import { calcPromo } from '../../utils/service/product';

import { useTranslation } from 'react-i18next';
import { select } from 'react-i18next/icu.macro';

export default function Payment() {
  const navigate = useNavigate();
  const [t] = useTranslation();

  const {
    selectedProduct,
    setTransactionResponse,
    setPaymentType,
    transactionResponse,
    paymentType,
    setRemainingTime,
    login,
    step,
    prevStep,
    nextStep,
  } = useContext(TransactionContext);

  const {
    attendeeDefault,
    cctokenDefault,
    customerDefault,
    transactionDefault,
  } = useModelDefault();

  const {
    attendeeError,
    hotelError,
    flightError,
    generalinfoError,
    customerError,
    customerFreeError,
    cctokenError,
    goToFieldWithError,
  } = useError();

  const { PIX, CREDITCARD, FREE } = usePaymentOption();

  const { categories } = useList();

  const { brlMask } = useMask();

  const { testMode, accountId } = useVar();

  const orders = {
    1: 'SINGLE',
    2: 'DOUBLE',
    3: 'SINGLE',
    4: 'DOUBLE',
    5: 'COTA',
    6: 'ONLINE',
  };

  const [order, setOrder] = useState(null);

  const [attendees, setAttendees] = useState(
    Array.from({ length: 1 }, () => {
      const data = selectedProduct?.description2?.split('|');
      attendeeDefault.type = login.type;
      if (login.type === 'F') {
        attendeeDefault.cp = login?.cp;
        attendeeDefault.birthdate = login?.birthdate;
        attendeeDefault.essenceclub = login?.essenceclub;
        attendeeDefault.regional = login?.regional;
        attendeeDefault.hotelcheckin = data[0];
        attendeeDefault.hotelcheckout = data[1];
        attendeeDefault.rating = login?.rating;
        attendeeDefault.cpcode = login?.cpcode || 'Sem cadastro';

        if (orders[data[2]] === 'SINGLE') {
          attendeeDefault.hotelaccommodation = '1 cama de casal';
        }
      }
      if (login.type === 'E') {
        attendeeDefault.name = login?.name;
        attendeeDefault.lastname = login?.lastname;
        attendeeDefault.hotelcheckin = data[0];
        attendeeDefault.hotelcheckout = data[1];
        attendeeDefault.email = login?.email;
        attendeeDefault.emailconfirmation = login?.email;
      }
      if (orders[data[2]] === 'COTA' || orders[data[2]] === 'ONLINE') {
        attendeeDefault.hotelcheckin = null;
        attendeeDefault.hotelcheckout = null;
        attendeeDefault.onewayarrivaldate = null;
        attendeeDefault.onewayflightarrivaldate = null;
        attendeeDefault.returnflightarrivaldate = null;
      }
      attendeeDefault.hotelname = login?.hotelname;
      attendeeDefault.hoteltype = orders[data[2]];
      attendeeDefault.id = login?.id;

      return attendeeDefault;
    }),
  );

  const [transaction, setTransaction] = useState(transactionDefault);
  const [customer, setCustomer] = useState(customerDefault);
  const [cctoken, setCctoken] = useState(cctokenDefault);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [htmlId, setHtmlId] = useState('');
  const [currentPromo, setCurrentPromo] = useState('');
  const [currentPrice, setCurrentPrice] = useState(selectedProduct.price);
  const [promocodeLoading, setPromoCodeLoading] = useState(false);
  const [promocodeError, setPromoCodeError] = useState(false);
  const [free, setFree] = useState(false);
  const [currentPriceColor, setCurrentPriceColor] = useState('#495057');
  const [showQrCode, setShowQrCode] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);

  const enablePromocode = false;

  const attendeeOnline = [
    'name',
    'email',
    'mobile',
    'address',
    'zipcode',
    'walkin',
  ];

  const attendeeProperties =
    login.type === 'F'
      ? selectedProduct.online
        ? attendeeOnline
        : [
            'name',
            'lastname',
            'namebadge',
            'document',
            'rg',
            'birthdate',
            'gender',
            'email',
            'emailconfirmation',
            'mobile',
            'address',
            'number',
            'addressline2',
            'district',
            'zipcode',
            'city',
            'state',
            'essenceclub',
            'cp',
            'regional',
          ]
      : selectedProduct.online
      ? [
          'name',
          'lastname',
          'namebadge',
          'role',
          'department',
          'vp',
          'document',
          'rg',
          'birthdate',
          'gender',
          'email',
          'emailconfirmation',
          'mobile',
          'walkin',
          // 'address',
          // 'number',
          // 'addressline2',
          // 'district',
          // 'zipcode',
          // 'city',
          // 'state',
        ]
      : [
          'name',
          'lastname',
          'namebadge',
          'role',
          'department',
          'vp',
          'document',
          'rg',
          'birthdate',
          'gender',
          'email',
          'emailconfirmation',
          'mobile',
          // 'address',
          // 'number',
          // 'addressline2',
          // 'district',
          // 'zipcode',
          // 'city',
          // 'state',
        ];
  const hotelProperties =
    login.type === 'F'
      ? [
          'hotelname',
          'hoteltype',
          'hotelcheckin',
          'hotelcheckout',
          'hotelaccommodation',
        ]
      : ['hotelname', 'hoteltype', 'hotelcheckin', 'hotelcheckout'];

  const generalinfoProperties =
    login.type === 'F'
      ? ['generalinfoauthorization']
      : [
          'generalinfocompany',
          'generalinfodocument',
          'generalinfocostcenter',
          'generalinfodepartment',
          'generalinfoauthorization',
        ];
  const validateAttendee = (properties, error) => {
    let errorFirstFor = false;

    let index = 0;
    const attendee = attendees[index];

    for (let i = 0; i < properties.length; i++) {
      let property = properties[i];
      let field = error[property];
      const invalid = field[1].func(attendee);
      // console.log(field);
      // console.log(property);
      // console.log(invalid);
      if (invalid) {
        const id = `attendee-${index}-${property}`;
        const msg = `fields.attendee.${property}`;
        setHtmlId(id);
        setMessage(t(msg));
        openModal();
        errorFirstFor = true;
        break;
      }
    }
    return !errorFirstFor;
  };

  const validate = (model, error, name) => {
    let errorFor = false;
    for (let property in error) {
      const field = error[property];
      for (let current in field) {
        if (field[current.toString()].func(model)) {
          const id = `${name}-${property}`;
          setHtmlId(id);
          const msg = `fields.${name}.${property}`;
          setMessage(t(msg));
          openModal();
          errorFor = true;
          break;
        }
      }
      if (errorFor) {
        break;
      }
    }
    return !errorFor;
  };

  const [adblock, setAdBlock] = useState(false);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    goToFieldWithError(htmlId);
  };

  const closeModalPromoCode = () => {
    setPromoCodeError(false);
    goToFieldWithError('current-promo');
  };
  const openModal = () => setOpen(true);

  const createPaymentToken = async () => {
    if (transaction.paymentType !== CREDITCARD) return '';

    const name = cctoken.name.split(' ');

    const firstName = name.shift();
    const lastName = name.pop();

    const expireAt = cctoken.expireAt.split('/');
    const month = expireAt.shift();
    const year = expireAt.pop();

    window.Iugu.setAccountID(accountId);
    window.Iugu.setTestMode(testMode);
    const creditCard = window.Iugu.CreditCard(
      cctoken.number,
      month,
      year,
      firstName,
      lastName,
      cctoken.code,
    );

    return new Promise(async (resolve) => {
      try {
        window.Iugu.createPaymentToken(creditCard, (response) => {
          if (response.errors) {
            Sentry.setContext('createPaymentTokenResponse', { response });
            Sentry.captureMessage('createPaymentToken');
            console.log('createPaymentToken', response.errors);
            resolve('');
          } else {
            resolve(response.id);
          }
        });
      } catch (error) {
        Sentry.setContext('error', { error });
        Sentry.captureMessage('createPaymentToken');
        console.log('createPaymentToken', error);
        resolve('');
      }
    });
  };

  const fillTransaction = (name, value) => {
    setTransaction((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillToken = (name, value) => {
    setCctoken((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillCustomer = (name, value) => {
    setCustomer((prevState) => ({ ...prevState, [name]: value }));
  };

  const fillAttendee = (name, value, index) =>
    setAttendees((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [name]: value };
      return newState;
    });

  const validations = {
    creditCard: () => {
      if (transaction.paymentType === CREDITCARD) {
        return validate(cctoken, cctokenError, 'cctoken');
      }
      return true;
    },
    customer: () => {
      if (transaction.paymentType === FREE) {
        return validate(customer, customerFreeError, 'customer');
      }
      return validate(customer, customerError, 'customer');
    },
  };

  const validateEmailAndDocument = async () => {
    const hotelType = attendees[0].hoteltype;
    const data = {
      email: attendees[0]?.email,
      document: attendees[0]?.document,
      eventId: transaction.eventId,
    };
    const response = await created(data);
    // console.log(response);
    if (response?.success) {
      return true;
    }
    const target = response?.data?.target;

    if (hotelType === 'ONLINE') {
      if (target === 'email') {
        const id = `attendee-0-${target}`;
        const msg =
          'Cadastro já realizado com esse e-mail, por favor, utilize outro!';
        setHtmlId(id);
        setMessage(t(msg));
        openModal();
        return false;
      }
      return true;
    } else {
      const id = `attendee-0-${target}`;
      const msg =
        target === 'email'
          ? 'Cadastro já realizado com esse e-mail, por favor, utilize outro!'
          : 'Cadastro já realizado com esse CPF, por favor, utilize outro!';
      setHtmlId(id);
      setMessage(t(msg));
      openModal();

      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabledButton(true);
    for (let validation in validations) {
      if (!validations[validation]()) {
        setDisabledButton(false);
        return;
      }
    }

    transaction.token = await createPaymentToken();
    transaction.customer = customer;
    transaction.attendees = attendees;
    transaction.installments =
      transaction.paymentType === CREDITCARD
        ? parseInt(cctoken.installments)
        : 1;

    //console.log(JSON.stringify(transaction));
    setLoading(true);
    const response = await transactions(transaction);

    setPaymentType(transaction.paymentType);
    setTransactionResponse(response);

    const generated = response.success && transaction.paymentType === PIX;
    setRemainingTime(480);
    setShowQrCode(generated);
    setShowSubmitButton(!generated);
    setLoading(false);

    if (transaction.paymentType !== PIX || response.success === false) {
      navigate('/finalizacao');
    }
    setTimeout(() => {
      setDisabledButton(false);
    }, 2000);
  };
  const paymentTypeOption = {
    free: selectedProduct.price,
    credit_card: selectedProduct.price,
    pix: selectedProduct.pixPrice,
  };

  const handlePromoCode = async (e) => {
    e.preventDefault();

    if (currentPromo === '' || promocodeError) {
      return;
    }

    setPromoCodeLoading(true);

    const promoCalc = await calcPromo(currentPromo, selectedProduct.id);

    if (promoCalc) {
      fillTransaction('promoCode', currentPromo);
      const newPrice = Number(promoCalc.price);
      if (newPrice === 0) {
        setFree(true);
        fillTransaction('paymentType', FREE);
      } else {
        setFree(false);
        fillTransaction('paymentType', PIX);
      }

      setCurrentPrice(newPrice);
      setPromoCodeLoading(false);
      setCurrentPriceColor('#26a47b');
      setTimeout(() => {
        setCurrentPriceColor('#495057');
      }, 3000);
    } else {
      setPromoCodeLoading(false);
      setPromoCodeError(true);
    }
  };

  const init = () => {
    const isBlockedByAdBlock = window.Iugu.utils.isBlockedByAdBlock();
    setAdBlock(isBlockedByAdBlock);

    if (selectedProduct?.description2) {
      const newOrder = selectedProduct.description2.split('|');
      setOrder(newOrder[2]);
    }

    setCurrentPrice(selectedProduct.price);
    setFree(selectedProduct.price === 0);
    if (selectedProduct.price === 0) {
      fillTransaction('paymentType', FREE);
    }
    fillTransaction('products', [selectedProduct.id]);

    setCurrentPrice(paymentTypeOption[transaction.paymentType]);
  };

  useEffect(() => {
    if (selectedProduct.id === '') {
      navigate('/');
    }
    init();
  }, []);

  const newAttendee = (
    <>
      {attendees.map((attendee, index) => (
        <Attendee
          key={index}
          index={index}
          attendee={attendee}
          length={attendees.length}
          type={login.type}
          categories={categories}
          fillAttendee={fillAttendee}
          online={selectedProduct.online}
        />
      ))}
      <Row className='row-btn-submit gap-2'>
        <button
          type='button'
          className='btn-submit'
          onClick={async (e) => {
            e.preventDefault();
            if (!validateAttendee(attendeeProperties, attendeeError)) {
              setDisabledButton(false);
              return;
            }
            const response = await validateEmailAndDocument();
            if (!response) {
              return;
            }
            nextStep();
          }}
        >
          Próximo
        </button>
      </Row>
    </>
  );
  const newHotel = (
    <>
      {attendees.map((attendee, index) => (
        <Hotel
          key={index}
          index={index}
          attendee={attendee}
          length={attendees.length}
          type={login.type}
          categories={categories}
          fillAttendee={fillAttendee}
          online={selectedProduct.online}
        />
      ))}
      <Row className='row-btn-submit gap-2'>
        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            prevStep();
          }}
        >
          Anterior
        </button>
        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            const h =
              login.type === 'F'
                ? [
                    'hotelname',
                    'hoteltype',
                    'hotelcheckin',
                    'hotelcheckout',
                    'hotelaccommodation',
                  ]
                : ['hotelname', 'hoteltype', 'hotelcheckin', 'hotelcheckout'];
            if (!validateAttendee(h, hotelError)) {
              setDisabledButton(false);
              return;
            }
            nextStep();
          }}
        >
          Próximo
        </button>
      </Row>
    </>
  );
  const newFlight = (
    <>
      {attendees.map((attendee, index) => (
        <Flight
          key={index}
          index={index}
          attendee={attendee}
          length={attendees.length}
          type={login.type}
          categories={categories}
          fillAttendee={fillAttendee}
          online={selectedProduct.online}
        />
      ))}
      <Row className='row-btn-submit gap-2'>
        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            prevStep();
          }}
        >
          Anterior
        </button>
        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            const flightissued =
              (attendees[0].type === 'F' &&
                attendees[0].flightissued !== 'Já emiti meu bilhete aéreo') ||
              attendees[0].flightissued === '';

            const flightProperties =
              login.type === 'F'
                ? attendees[0].logistics === ''
                  ? ['logistics']
                  : attendees[0].logistics ===
                    'Aéreo + transfer (Aeroporto x Hotel e Hotel x Aeroporto)'
                  ? attendees[0].flightissued === 'Já emiti meu bilhete aéreo'
                    ? [
                        'logistics',
                        'flightissued',
                        'onewayflightarrivaldate',
                        'onewayflightairline',
                        'onewayflightnumber',
                        'onewayflightfrom',
                        'onewayflightto',
                        // 'onewayflightlocator',
                        // 'onewayflighteticket',
                        'onewayflightboardingtime',
                        'onewayflightarrivaltime',
                        'returnflightarrivaldate',
                        'returnflightairline',
                        'returnflightnumber',
                        // 'returnflightlocator',
                        // 'returnflighteticket',
                        'returnflightfrom',
                        'returnflightto',
                        'returnflightboardingtime',
                        'returnflightarrivaltime',
                      ]
                    : ['logistics', 'flightissued']
                  : attendees[0].logistics === 'Carro próprio'
                  ? ['logistics']
                  : ['logistics', 'transfer']
                : attendees[0].logistics === ''
                ? ['logistics']
                : attendees[0].logistics ===
                  'Transfer (Apenas para Colaboradores de São Paulo)'
                ? ['logistics', 'transfer']
                : ['logistics'];

            // const flightProperties =
            //   login.type === 'F'
            //     ? flightissued
            //       ? ['logistics', 'flightissued']
            //       : [
            //           'logistics',
            //           'flightissued',
            //           'onewayflightarrivaldate',
            //           'onewayflightairline',
            //           'onewayflightnumber',
            //           'onewayflightfrom',
            //           'onewayflightto',
            //           'onewayflightlocator',
            //           'onewayflighteticket',
            //           'onewayflightboardingtime',
            //           'onewayflightarrivaltime',
            //           'returnflightarrivaldate',
            //           'returnflightairline',
            //           'returnflightnumber',
            //           'returnflightlocator',
            //           'returnflighteticket',
            //           'returnflightfrom',
            //           'returnflightto',
            //           'returnflightboardingtime',
            //           'returnflightarrivaltime',
            //         ]
            //     : ['logistics'];
            if (!validateAttendee(flightProperties, flightError)) {
              setDisabledButton(false);
              return;
            }
            if (flightissued) {
              fillAttendee('onewayarrivaldate', null, 0);
              fillAttendee('onewayflightarrivaldate', null, 0);
              fillAttendee('returnflightarrivaldate', null, 0);
            }
            nextStep();
          }}
        >
          Próximo
        </button>
      </Row>
    </>
  );
  const newGeneralInfo = (
    <>
      {attendees.map((attendee, index) => (
        <GeneralInfo
          key={index}
          index={index}
          attendee={attendee}
          length={attendees.length}
          type={login.type}
          categories={categories}
          fillAttendee={fillAttendee}
          online={selectedProduct.online}
        />
      ))}
      <Row className='row-btn-submit gap-2'>
        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            prevStep();
          }}
        >
          Anterior
        </button>
        <button
          type='button'
          className='btn-submit'
          onClick={(e) => {
            e.preventDefault();
            if (!validateAttendee(generalinfoProperties, generalinfoError)) {
              setDisabledButton(false);
              return;
            }
            nextStep();
          }}
        >
          Próximo
        </button>
      </Row>
    </>
  );
  const payment = (
    <>
      {!free && (
        <>
          <h3
            className='title2'
            style={{ marginTop: 20 }}
          >
            {t('payment.option.title')}
          </h3>
          <Row>
            <Col className='div-option-payment'>
              <h6>{t('payment.option.pix')}</h6>
              <img
                alt='Forma de pagamento, pix'
                src={PixImg}
                style={{ width: 40, margin: '10px 0' }}
              />
              <input
                id='transaction-payment-type-pix'
                type='radio'
                className='form-check-input'
                value={transaction.paymentType}
                checked={transaction.paymentType === PIX}
                onChange={(e) => {
                  if (transactionResponse.success && paymentType === PIX) {
                    setShowQrCode(true);
                    setShowSubmitButton(false);
                  }
                  fillTransaction('paymentType', PIX);
                }}
              />
            </Col>
            <Col className='div-option-payment'>
              <h6>{t('payment.option.creditcard')}</h6>
              <img
                alt='Forma de pagamento, cartão de crédito'
                src={CartoesImg}
                style={{ width: 95, margin: '10px 0' }}
              />
              <input
                id='transaction-payment-type-credit-card'
                type='radio'
                className='form-check-input'
                value={transaction.paymentType}
                checked={transaction.paymentType === CREDITCARD}
                onChange={(e) => {
                  setShowQrCode(false);
                  setShowSubmitButton(true);
                  fillTransaction('paymentType', CREDITCARD);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              className='d-flex justify-content-left'
            >
              {/* <span className='badge text-dark'>
                Taxa de transação 6,5% (PIX)
              </span> */}
            </Col>
            <Col
              md={6}
              className='d-flex justify-content-left'
            >
              <span className='badge text-dark text-wrap'>
                *A prazo, acréscimo de 2% ao mês, taxas convencionais das
                operadoras de cartão de crédito mais taxas bancárias.
              </span>
            </Col>
          </Row>
        </>
      )}
      {transaction.paymentType === CREDITCARD && (
        <Token
          cctoken={cctoken}
          fillToken={fillToken}
          amount={currentPrice}
          maxInstallments={selectedProduct.installments}
          product={selectedProduct}
        />
      )}
      <Customer
        customer={customer}
        fillCustomer={fillCustomer}
        free={free}
        paymentType={transaction.paymentType}
      />
      {showQrCode && (
        <Pix
          qrCode={transactionResponse.data?.pix?.qrcode}
          qrCodeText={transactionResponse?.data?.pix?.qrcode_text}
          transactionId={transactionResponse?.data?.transactionId}
        />
      )}
      {showQrCode === false && (
        <Row className='row-btn-submit gap-2'>
          <button
            type='button'
            className='btn-submit'
            onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}
          >
            Anterior
          </button>

          <button
            type='submit'
            id='btn-buy'
            className='btn-submit'
            onClick={handleSubmit}
            disabled={disabledButton}
          >
            {loading ? (
              <img
                style={{
                  width: '24px',
                }}
                src={Loading}
                alt='Efetuando a transação, aguarde'
              />
            ) : (
              <>{t('cart.buy')}</>
            )}
          </button>
        </Row>
      )}
    </>
  );
  const Modals = (
    <>
      <Modal
        open={adblock}
        closeModal={(e) => {
          setAdBlock(false);
          navigate('/');
        }}
      >
        <WarningAdBlock />
      </Modal>
      <Modal
        open={promocodeError}
        closeModal={closeModalPromoCode}
      >
        {t('payment.promocode.title')} {currentPromo}{' '}
        {t('payment.promocode.error')}
      </Modal>
      <Modal
        open={open}
        closeModal={closeModal}
      >
        {message}
      </Modal>
    </>
  );

  const main = {
    1: newAttendee,
    2: newHotel,
    3: newFlight,
    4: newGeneralInfo,
    5: payment,
  };

  const sub = {
    1: newAttendee,
    2: newGeneralInfo,
    3: payment,
  };

  const newOrder = {
    1: main,
    2: main,
    3: main,
    4: main,
    5: sub,
    6: sub,
  };

  return (
    <section id='section-payment'>
      {Modals}
      <Header />
      <Row>
        <Card className='card-payment'>
          <CardBody>
            <Row>
              <Col md={6}>
                <Location />
              </Col>
              <Col md={6}>
                <div className='total-amount'>
                  <div>{selectedProduct.description}</div>
                  <div>
                    {brlMask(paymentTypeOption[transaction.paymentType])}
                  </div>
                </div>

                <div className='total-amount'>
                  <div className='title2'>{t('payment.amount')}</div>
                  <div style={{ color: currentPriceColor }}>
                    {brlMask(
                      transaction.paymentType === FREE
                        ? selectedProduct.price
                        : transaction.paymentType === PIX
                        ? selectedProduct.pixPrice
                        : transaction.paymentType === CREDITCARD &&
                          parseInt(cctoken.installments) === 1
                        ? selectedProduct.price
                        : selectedProduct[
                            `i${cctoken.installments
                              .toString()
                              .padStart(2, '0')}xPrice`
                          ],
                      // cctoken.installments > 1 &&
                      //   transaction.paymentType === CREDITCARD
                      //   ? selectedProduct[
                      //       `i${cctoken.installments
                      //         .toString()
                      //         .padStart(2, '0')}xPrice`
                      //     ]
                      //   : cctoken.installments === 1 &&
                      //     transaction.paymentType === CREDITCARD
                      //   ? selectedProduct.price
                      //   : selectedProduct.pixPrice,
                    )}
                  </div>
                </div>

                {enablePromocode && (
                  <>
                    <div className='total-amount'>
                      <div className='title2'>
                        {t('payment.promocode.title')}
                      </div>
                    </div>
                    <form
                      autoComplete='off'
                      onKeyDown={(e) => e.preventDefault()}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className='total-amount'>
                        <div className='promo-wrapper'>
                          <input
                            type='text'
                            id='current-promo'
                            className='form-control'
                            value={currentPromo}
                            autoComplete='off'
                            onChange={(e) =>
                              setCurrentPromo(
                                e.target.value.trim().toUpperCase(),
                              )
                            }
                          />
                          <button
                            id='btn-promo-code'
                            className='btn-submit'
                            onClick={handlePromoCode}
                          >
                            {promocodeLoading ? (
                              <img
                                style={{
                                  width: '16px',
                                }}
                                src={Loading}
                                alt='Aplicando cupom, aguarde'
                              />
                            ) : (
                              <>{t('payment.promocode.apply')}</>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </Col>
            </Row>

            <form autoComplete='off'>{order && newOrder[order][step]}</form>
          </CardBody>
        </Card>
      </Row>
    </section>
  );
}
