import React from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useTranslation } from 'react-i18next';
export const Hotel = ({ index, attendee, fillAttendee, length, type }) => {
  const [t] = useTranslation();

  const { alphabetMask } = useMask();

  const accommodations = t(
    'payment.attendee.hotel.hotelaccommodation.options',
    {
      returnObjects: true,
    },
  );

  const franchisee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.hotel.title')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelname`}
          >
            {t('payment.attendee.hotel.name')}*
          </label>
          <input
            id={`attendee-${index}-hotelname`}
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.hotelname}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('hotelname', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hoteltype`}
          >
            {t('payment.attendee.hotel.type')}*
          </label>
          <input
            id={`attendee-${index}-hoteltype`}
            className='form-control'
            autoComplete='off'
            value={attendee.hoteltype}
            disabled={true}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('hoteltype', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelcheckin`}
          >
            {t('payment.attendee.hotel.checkin')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-hotelcheckin`}
            min='2024-01-01'
            max='2024-12-31'
            className='form-control'
            disabled={true}
            autoComplete='off'
            value={attendee.hotelcheckin}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelcheckin', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelcheckout`}
          >
            {t('payment.attendee.hotel.checkout')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-hotelcheckout`}
            min='2024-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.hotelcheckout}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelcheckout', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelaccommodation`}
          >
            {t('payment.attendee.hotel.hotelaccommodation.name')}*
          </label>
          <select
            id={`attendee-${index}-hotelaccommodation`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.hotelaccommodation}
            disabled={attendee.hoteltype === 'SINGLE'}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('hotelaccommodation', value, index);
            }}
          >
            {accommodations.map((accommodation, index) => (
              <option
                key={index}
                value={accommodation.code}
              >
                {accommodation.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className='mt-3'>
        {/* <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-hotelearlycheckin`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.hotelearlycheckin}
              onChange={(e) =>
                fillAttendee(
                  'hotelearlycheckin',
                  !attendee.hotelearlycheckin,
                  index,
                )
              }
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.hotel.earlycheckin')}
            </label>
          </div>
        </Col> */}
        {/* <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-hotellatecheckout`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.hotellatecheckout}
              onChange={(e) =>
                fillAttendee(
                  'hotellatecheckout',
                  !attendee.hotellatecheckout,
                  index,
                )
              }
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.hotel.latecheckout')}
            </label>
          </div>
        </Col> */}
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelextendstay`}
          >
            {t('payment.attendee.hotel.hotelextendstay')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-hotelextendstay`}
            className='form-control'
            autoComplete='off'
            value={attendee.hotelextendstay}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelextendstay', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelextendstay`}
          >
            {t('payment.attendee.hotel.restriction')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-hotelrestriction`}
            className='form-control'
            autoComplete='off'
            value={attendee.hotelrestriction}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelrestriction', newValue, index);
            }}
          />
        </Col>
      </Row>
    </>
  );

  const employee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.hotel.title')}
      </h3>
      {/* <Row className='mt-4 mb-2'>
        <p>
          Nesse evento você terá a possibilidade de escolher o acompanhante
          através do aplicativo do evento: “
          <strong>Eventos Franquias GB</strong>”. Após concluir o seu cadastro
          você receberá um e-mail com as informações do MATCH de hospedagem.
          Fique atento a sua caixa de entrada!
        </p>
      </Row> */}
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelname`}
          >
            {t('payment.attendee.hotel.name')}*
          </label>
          <input
            id={`attendee-${index}-hotelname`}
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.hotelname}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('hotelname', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hoteltype`}
          >
            {t('payment.attendee.hotel.type')}*
          </label>
          <input
            id={`attendee-${index}-hoteltype`}
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.hoteltype}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('hoteltype', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelcheckin`}
          >
            {t('payment.attendee.hotel.checkin')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-hotelcheckin`}
            min='2024-01-01'
            max='2024-12-31'
            disabled={true}
            className='form-control'
            autoComplete='off'
            value={attendee.hotelcheckin}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelcheckin', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelcheckout`}
          >
            {t('payment.attendee.hotel.checkout')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-hotelcheckout`}
            min='2024-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.hotelcheckout}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelcheckout', newValue, index);
            }}
          />
        </Col>
        <Row className='mt-2'>
          <Col md={12}>
            <span className='badge text-dark text-start'>
              * Referente ao match de hospedagem, enviaremos um link no seu
              e-mail e mais informações via Slack!
            </span>
          </Col>
        </Row>
      </Row>
      <Row className='mt-3'>
        {/* <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-hotelearlycheckin`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.hotelearlycheckin}
              onChange={(e) =>
                fillAttendee(
                  'hotelearlycheckin',
                  !attendee.hotelearlycheckin,
                  index,
                )
              }
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.hotel.earlycheckin')}
            </label>
          </div>
        </Col> */}
        {/* <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-hotellatecheckout`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.hotellatecheckout}
              onChange={(e) =>
                fillAttendee(
                  'hotellatecheckout',
                  !attendee.hotellatecheckout,
                  index,
                )
              }
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.hotel.latecheckout')}
            </label>
          </div>
        </Col> */}
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelextendstay`}
          >
            {t('payment.attendee.hotel.hotelextendstay')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-hotelextendstay`}
            className='form-control'
            autoComplete='off'
            value={attendee.hotelextendstay}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelextendstay', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelnote`}
          >
            Caso seja necessário alterar o check in ou check out informe abaixo:
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-hotelnote`}
            className='form-control'
            autoComplete='off'
            value={attendee.hotelnote}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelnote', newValue, index);
            }}
          />
        </Col>
      </Row>
    </>
  );

  return type === 'F' ? franchisee : employee;
};
