import { cnpj, cpf } from 'cpf-cnpj-validator';

export const useError = () => {
  const isEmpty = (value) => value?.trim() === '';

  const isEmptyOtherCategory = (selectedOption, value) =>
    selectedOption === 'Outros' && value?.trim() === '';

  const zipCodeRegex = /^\d{5}-\d{3}$/;
  const isNotZipCode = (value) => !zipCodeRegex.test(value);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isEmail = (value) => !emailRegex.test(value);

  const mobileRegex = /^\(\d{2}\) 9\d{4}-\d{4}$/;
  const isMobile = (value) => {
    return !mobileRegex.test(value);
  };

  const calcDigit = (numbers, length, verifyingDigit = 0) => {
    const sum = numbers.reduce(
      (previous, current, index) =>
        parseInt(previous) + parseInt(current) * (length - index),
      0,
    );
    return ((verifyingDigit * 9 + sum) % 11) % 10;
  };

  const validateCpf = (cpfStr) => {
    cpfStr = cpfStr.replace(/\D/g, '');
    const elevenNumbers = cpfStr.length === 11;
    if (!elevenNumbers) {
      return true;
    }

    const regex = /^(\d)\1{10}$/;
    const repeatedNumbers = regex.test(cpfStr);
    if (repeatedNumbers) {
      return true;
    }

    const verifyingDigit = cpfStr.substring(9, 11);
    const numbers = cpfStr.substring(0, 9).split('').reverse();
    const firstDigit = calcDigit(numbers, 9);
    const secondDigit = calcDigit(numbers, 8, firstDigit);
    return verifyingDigit !== `${firstDigit}${secondDigit}`;
  };

  const validateCreditCardNumber = (value) => {
    if ('' === value || undefined === value || null === value) return false;
    const test = '4111111111111111';

    if (test === value) return true;

    value = value.replace(/\D/g, '');
    var nCheck = 0,
      nDigit = 0,
      bEven = false;

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  };

  const cvvCodeRegex = /^\d{3,4}$/;

  const isNotCvv = (value) => !cvvCodeRegex.test(value);

  const isValidTime = (time) => {
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(time);
  };

  const isValidTimeBetween = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const date1 = new Date(0, 0, 0, hours1, minutes1);
    const date2 = new Date(0, 0, 0, hours2, minutes2);
    return date1 < date2;
  };

  const isValid2Dates = (value1, value2) => {
    const newDate1 = new Date(value1);
    const newDate2 = new Date(value2);
    const minDate = new Date('1923-01-01');
    const maxDate = new Date('2024-12-31');
    return newDate2 > minDate && newDate2 < maxDate && newDate2 > newDate1;
  };
  const isValid2DateFlight = (value1, value2) => {
    const newDate1 = new Date(value1);
    const newDate2 = new Date(value2);
    const minDate = new Date('1923-01-01');
    const maxDate = new Date('2024-12-31');
    return newDate2 > minDate && newDate2 < maxDate && newDate2 >= newDate1;
  };

  const isValidDate = (value) => {
    const newDate = new Date(value);
    const minDate = new Date('1923-01-01');
    const maxDate = new Date('2024-12-31');

    return newDate > minDate && newDate < maxDate;
  };
  const attendeeError = {
    name: {
      1: {
        func: (attendee) => isEmpty(attendee.name),
      },
    },
    lastname: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.lastname);
        },
      },
    },
    namebadge: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.namebadge);
        },
      },
    },
    role: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.role);
        },
      },
    },
    department: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.department);
        },
      },
    },
    vp: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.vp);
        },
      },
    },
    document: {
      1: {
        func: (attendee) => {
          return !cpf.isValid(attendee.document);
        },
      },
    },
    rg: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.rg);
        },
      },
    },
    birthdate: {
      1: {
        func: (attendee) => {
          return !isValidDate(attendee.birthdate);
        },
      },
    },
    gender: {
      1: {
        func: (attendee) => isEmpty(attendee.gender),
      },
    },
    email: {
      1: {
        func: (attendee) => {
          return isEmail(attendee.email);
        },
      },
    },
    emailconfirmation: {
      1: {
        func: (attendee) => {
          return (
            isEmail(attendee.emailconfirmation) ||
            !(attendee.email === attendee.emailconfirmation)
          );
        },
      },
    },
    mobile: {
      1: {
        func: (attendee) => {
          return isMobile(attendee.mobile);
        },
      },
    },
    address: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.address);
        },
      },
    },
    number: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.number);
        },
      },
    },
    addressline2: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.addressline2);
        },
      },
    },
    district: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.district);
        },
      },
    },
    zipcode: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.zipcode);
        },
      },
    },
    city: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.city);
        },
      },
    },
    state: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.state);
        },
      },
    },
    cpcode: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.cpcode);
        },
      },
    },
    essenceclub: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.essenceclub);
        },
      },
    },
    cp: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.cp);
        },
      },
    },
    regional: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.regional);
        },
      },
    },
    walkin: {
      1: {
        func: (attendee) => {
          return (
            attendee.d20 === false &&
            attendee.d21 === false &&
            attendee.d22 === false &&
            attendee.d8 === false
          );
        },
      },
    },
  };

  const hotelError = {
    hotelname: {
      1: {
        func: (attendee) => isEmpty(attendee.hotelname),
      },
    },
    hoteltype: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.hoteltype);
        },
      },
    },
    hotelcheckin: {
      1: {
        func: (attendee) => {
          return !isValidDate(attendee.hotelcheckin);
        },
      },
    },
    hotelcheckout: {
      1: {
        func: (attendee) => {
          return !isValid2Dates(attendee.hotelcheckin, attendee.hotelcheckout);
        },
      },
    },
    hotelaccommodation: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.hotelaccommodation);
        },
      },
    },
  };
  const flightError = {
    logistics: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.logistics);
        },
      },
    },
    transfer: {
      1: {
        func: (attendee) => isEmpty(attendee.transfer),
      },
    },
    flightissued: {
      1: {
        func: (attendee) => isEmpty(attendee.flightissued),
      },
    },
    onewayflightarrivaldate: {
      1: {
        func: (attendee) => {
          return !isValidDate(attendee.onewayflightarrivaldate);
        },
      },
    },
    onewayflightairline: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.onewayflightairline);
        },
      },
    },
    onewayflightnumber: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.onewayflightnumber);
        },
      },
    },
    onewayflightlocator: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.onewayflightlocator);
        },
      },
    },
    onewayflighteticket: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.onewayflighteticket);
        },
      },
    },
    onewayflightfrom: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.onewayflightfrom);
        },
      },
    },

    onewayflightto: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.onewayflightto);
        },
      },
    },
    onewayflightboardingtime: {
      1: {
        func: (attendee) => {
          return !isValidTime(attendee.onewayflightboardingtime);
        },
      },
    },
    onewayflightarrivaltime: {
      1: {
        func: (attendee) => {
          return !isValidTime(attendee.onewayflightarrivaltime);
        },
      },
    },
    returnflightarrivaldate: {
      1: {
        func: (attendee) => {
          return !isValid2DateFlight(
            attendee.onewayflightarrivaldate,
            attendee.returnflightarrivaldate,
          );
        },
      },
    },
    returnflightairline: {
      1: {
        func: (attendee) => isEmpty(attendee.returnflightairline),
      },
    },
    returnflightnumber: {
      1: {
        func: (attendee) => isEmpty(attendee.returnflightnumber),
      },
    },
    returnflightlocator: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.returnflightlocator);
        },
      },
    },
    returnflighteticket: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.returnflighteticket);
        },
      },
    },
    returnflightfrom: {
      1: {
        func: (attendee) => isEmpty(attendee.returnflightfrom),
      },
    },
    returnflightto: {
      1: {
        func: (attendee) => isEmpty(attendee.returnflightto),
      },
    },
    returnflightboardingtime: {
      1: {
        func: (attendee) => !isValidTime(attendee.returnflightboardingtime),
      },
    },
    returnflightarrivaltime: {
      1: {
        func: (attendee) => !isValidTime(attendee.returnflightarrivaltime),
      },
    },
  };

  const generalinfoError = {
    generalinfocompany: {
      1: {
        func: (attendee) => isEmpty(attendee.generalinfocompany),
      },
    },
    generalinfodocument: {
      1: {
        func: (attendee) => {
          return !cnpj.isValid(attendee.generalinfodocument);
        },
      },
    },
    generalinfocostcenter: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.generalinfocostcenter);
        },
      },
    },
    generalinfodepartment: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.generalinfodepartment);
        },
      },
    },
    generalinfotshirtsize: {
      1: {
        func: (attendee) => {
          return isEmpty(attendee.generalinfotshirtsize);
        },
      },
    },

    generalinfofoodrestriction: {
      1: {
        func: (attendee) => {
          return isEmail(attendee.email);
        },
      },
    },
    generalinforestriction: {
      1: {
        func: (attendee) => {
          return (
            isEmail(attendee.emailconfirmation) ||
            !(attendee.email === attendee.emailconfirmation)
          );
        },
      },
    },
    generalinfoanothernotes: {
      1: {
        func: (attendee) => {
          return isMobile(attendee.mobile);
        },
      },
    },
    generalinfoauthorization: {
      1: {
        func: (attendee) => {
          return attendee.generalinfoauthorization === false;
        },
      },
    },
    generalinfoauthorization: {
      1: {
        func: (attendee) => {
          return attendee.generalinfoauthorization === false;
        },
      },
    },
  };

  const customerError = {
    // doyouagree: {
    //   1: {
    //     func: (customer) => customer.doyouagree === false,
    //     msg: 'Em dados de envio, o campo nome não foi preenchido!',
    //   },
    // },
    name: {
      1: {
        func: (customer) => isEmpty(customer.name),
        msg: 'Em dados de cobrança, o campo nome não foi preenchido!',
      },
    },
    email: {
      1: {
        func: (customer) => {
          return isEmail(customer.email);
        },
        msg: 'Em dados de cobrança, o valor em e-mail é inválido!',
      },
    },
    document: {
      1: {
        func: (customer) => {
          const document = customer.document.replace(/\D/g, '');
          const response = cpf.isValid(document)
            ? false
            : cnpj.isValid(document)
            ? false
            : true;
          return response;
        },
        msg: 'Em dados de cobrança, o campo endereço não foi preenchido!',
      },
    },
    // mobile: {
    //   1: {
    //     func: (customer) => isEmpty(customer.mobile),
    //     msg: 'Em dados de cobrança, o campo endereço não foi preenchido!',
    //   },
    // },
    address: {
      1: {
        func: (customer) => isEmpty(customer.address),
        msg: 'Em dados de cobrança, o campo endereço não foi preenchido!',
      },
    },
    // city: {
    //   1: {
    //     func: (customer) => isEmpty(customer.city),
    //     msg: 'Em dados de cobrança, o campo endereço não foi preenchido!',
    //   },
    // },
    // state: {
    //   1: {
    //     func: (customer) => isEmpty(customer.state),
    //     msg: 'Em dados de cobrança, o campo endereço não foi preenchido!',
    //   },
    // },
  };

  const customerFreeError = {
    name: {
      1: {
        func: (customer) => isEmpty(customer.name),
        msg: 'Em dados de envio, o campo nome não foi preenchido!',
      },
    },
    email: {
      1: {
        func: (customer) => {
          return isEmail(customer.email);
        },
        msg: 'Em dados de envio, o valor em e-mail é inválido!',
      },
    },
  };
  const cctokenError = {
    number: {
      1: {
        func: (cctoken) => !validateCreditCardNumber(cctoken.number),
        msg: 'Em cartão de crédito, o valor do campo número do cartão é inválido!',
      },
    },
    name: {
      1: {
        func: (cctoken) => isEmpty(cctoken.name),
        msg: 'Em cartão de crédito, o campo nome impresso no cartão não foi preenchido!',
      },
    },
    expireAt: {
      1: {
        func: (cctoken) => isEmpty(cctoken.expireAt),
        msg: 'Em cartão de crédito, o campo data de validade não foi preenchido!',
      },
    },
    code: {
      1: {
        func: (cctoken) => isNotCvv(cctoken.code),
        msg: 'Em cartão de crédito, o valor do campo código é inválido!',
      },
    },
  };

  const loginError = {
    cp: {
      1: {
        func: (login) => login.type === 'F' && isEmpty(login.cp),
      },
    },
    document: {
      1: {
        func: (login) => login.type === 'F' && login?.document?.length !== 4,
      },
    },
    birthdate: {
      1: {
        func: (login) => login.type === 'F' && !isValidDate(login.birthdate),
      },
    },
    email: {
      1: {
        func: (login) => login.type === 'E' && isEmail(login.email),
      },
    },
  };

  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    document.getElementById(id).focus();
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return {
    attendeeError,
    hotelError,
    flightError,
    generalinfoError,
    customerError,
    cctokenError,
    customerFreeError,
    loginError,
    goToFieldWithError,
  };
};
