export const useModelDefault = () => {
  const attendeeDefault = {
    name: '',
    lastname: '',
    namebadge: '',
    document: '',
    birthdate: '',
    email: '',
    emailconfirmation: '',
    mobile: '',
    essenceclub: '',
    cp: '',
    regional: '',
    type: '',
    gender: '',

    hotelname: '',
    hoteltype: '',
    hotelcheckin: '',
    hotelcheckout: '',
    hotelearlycheckin: false,
    hotellatecheckout: false,
    hotelrestriction: '',
    hotelaccommodation: '',
    hotelnote: '',

    flightissued: '',

    onewayflightarrivaldate: '',
    onewayflightairline: '',
    onewayflightnumber: '',
    onewayflightlocator: '',
    onewayflighteticket: '',
    onewayflightfrom: '',
    onewayflightto: '',
    onewayflightboardingtime: '',
    onewayflightarrivaltime: '',

    returnflightarrivaldate: '',
    returnflightairline: '',
    returnflightnumber: '',
    returnflightlocator: '',
    returnflighteticket: '',
    returnflightfrom: '',
    returnflightto: '',
    returnflightboardingtime: '',
    returnflightarrivaltime: '',

    generalinfocompany: '',
    generalinfodocument: '',
    generalinfocostcenter: '',
    generalinfodepartment: '',
    generalinfotshirtsize: '',
    generalinfofoodrestriction: '',
    generalinforestriction: '',
    generalinfoanothernotes: '',
    generalinfoauthorization: false,

    logistics: '',
    transfer: '',
    rating: '',

    role: '',
    department: '',
    vp: '',
    rg: '',

    address: '',
    number: '',
    addressline2: '',
    district: '',
    zipcode: '',
    city: '',
    state: '',
    cpcode: 'Sem cadastro',

    walkinattendee: '',
    walkin: '',
    d20: false,
    d21: false,
    d22: false,
    d8: false,
  };

  const cctokenDefault = {
    number: '',
    name: '',
    expireAt: '',
    code: '',
    email: '',
    installments: 1,
  };

  const customerDefault = {
    name: '',
    email: '',
    // doyouagree: false,
    document: '',
    // mobile: '',
    address: '',
    // city: '',
    // state: '',
  };

  const transactionDefault = {
    promoCode: 'NOCODE',
    eventId: process.env.REACT_APP_EVENT_ID,
    eventCode: parseInt(process.env.REACT_APP_EVENT_ID_MICE),
    attendees: [],
    products: [],
    paymentType: 'pix',
    customer: null,
    token: '',
    installments: 1,
    recaptcha: '',
  };

  const loginDefault = {
    eventId: process.env.REACT_APP_EVENT_ID,
    type: '',
    cp: '',
    document: '',
    birthdate: '',
    email: '',
  };

  return {
    attendeeDefault,
    cctokenDefault,
    customerDefault,
    transactionDefault,
    loginDefault,
  };
};
