import Loading from '../../assets/images/loading.gif';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import Header from '../../components/header';
import { Modal } from '../../components/modal';
import { useMask } from '../../hooks/useMask';
import { useError } from '../../hooks/useError';

import { TransactionContext } from '../../contexts/TransactionContext';
import { getTokenRecaptcha, signin } from '../../utils/service/payment';

export default function Home() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const { login, fillLogin, setProducts } = useContext(TransactionContext);
  const { numberMask, emailMask } = useMask();

  const cps = t('payment.attendee.cp.options', {
    returnObjects: true,
  });

  const { loginError, goToFieldWithError } = useError();

  const [htmlId, setHtmlId] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState(cps);
  const [isListVisible, setIsListVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    const term = e.target.value;
    fillLogin('cp', term);
    const filtered = cps.filter((option) =>
      option.code.toLowerCase().includes(term.toLowerCase()),
    );
    setFilteredOptions(filtered);
    setIsListVisible(true);
  };

  const handleSelect = (selectedOption) => {
    fillLogin('cp', selectedOption.code);
    setIsListVisible(false);
  };

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  const closeModal = () => {
    setOpen(false);
    goToFieldWithError(htmlId);
  };

  const validate = (model, error) => {
    let errorFor = false;
    for (let property in error) {
      const field = error[property];
      for (let current in field) {
        if (field[current.toString()].func(model)) {
          const id = `home-${property}`;
          setHtmlId(id);
          const msg = `home.errors.${property}_empty`;
          setMessage(t(msg));
          setOpen(true);
          errorFor = true;
          break;
        }
      }
      if (errorFor) {
        break;
      }
    }
    return !errorFor;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = validate(login, loginError, 'login');
    if (!success) {
      return;
    }

    login.recaptcha = await getTokenRecaptcha();

    setLoading(true);
    const response = await signin(login);
    //console.log(response);
    setLoading(false);
    if (response.success) {
      if (response.data.products?.length === 0) {
        setHtmlId(login?.type === 'E' ? 'home-email' : 'home-cp');
        setMessage(t(`home.errors.product_not_available`));
        setOpen(true);
      } else {
        if (response.data.purchased) {
          setHtmlId(login?.type === 'E' ? 'home-email' : 'home-cp');
          setMessage(t(`home.errors.already_bought`));
          setOpen(true);
        } else {
          const loginResponse = response.data.login;

          if (login.type === 'F') {
            fillLogin('regional', loginResponse.regional);
            fillLogin('essenceclub', loginResponse.essenceclub);
            fillLogin('rating', loginResponse.rating);
            fillLogin('cpcode', loginResponse.cpcode);
          }
          if (login.type === 'E') {
            fillLogin('name', loginResponse.name);
            fillLogin('lastname', loginResponse.lastname);
            // fillLogin('hoteltype', loginResponse.hoteltype);
          }
          fillLogin('id', loginResponse.id);
          fillLogin('hotelname', loginResponse.hotelname);

          setProducts(response.data.products);
          navigate('/carrinho');
        }
      }
    } else {
      setHtmlId(login?.type === 'E' ? 'home-email' : 'home-cp');
      setMessage(t(`home.errors.not_authorized`));
      setOpen(true);
    }
  };
  useEffect(() => {
    document.title = t('title');
  }, [login, t, loading]);

  const choices = (
    <Card className='card-cart'>
      <CardBody className='justify-content-center'>
        <Row className='mt-2'>
          <Col className='d-flex gap-3 justify-content-center'>
            <button
              className='btn-home'
              onClick={(e) => {
                e.preventDefault();
                fillLogin('type', 'F');
              }}
            >
              Franqueado
            </button>
            <button
              className='btn-home'
              onClick={(e) => {
                e.preventDefault();
                fillLogin('type', 'E');
              }}
            >
              Convidado GB
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  const franchisee = (
    <Card className='card-cart'>
      <CardBody className='justify-content-center'>
        <Row className='mt-2 justify-content-center'>
          <Col md={6}>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`home-cp`}
            >
              {t('login.cp')}*
            </label>
            <InputGroup>
              <Input
                id={'home-cp'}
                placeholder='Pesquisar...'
                value={login.cp}
                onChange={handleSearch}
                onClick={toggleListVisibility}
              ></Input>
            </InputGroup>
            {isListVisible && (
              <ListGroup>
                {filteredOptions.map((cp, index) => (
                  <ListGroupItem
                    key={index}
                    value={cp.code}
                    action
                    onClick={() => handleSelect(cp)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        fillLogin('cp', cp.code);
                        setIsListVisible(false);
                      }
                    }}
                    tabIndex={0}
                  >
                    {cp.name}
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </Col>
        </Row>
        <Row className='mt-2 justify-content-center'>
          <Col md={6}>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`home-document`}
            >
              {t('login.document')}*
            </label>
            <input
              id={`home-document`}
              className='form-control'
              autoComplete='off'
              maxLength={4}
              value={login.document}
              onChange={(e) => {
                const { value } = e.target;
                let newValue = numberMask(value);
                fillLogin('document', newValue);
              }}
            />
          </Col>
        </Row>
        <Row className='mt-2 justify-content-center'>
          <Col md={6}>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`home-birthdate`}
            >
              {t('login.birthdate')}*
            </label>
            <input
              type='date'
              id={`home-birthdate`}
              min='1923-01-01'
              max='2024-12-31'
              className='form-control'
              autoComplete='off'
              maxLength={4}
              value={login.birthdate}
              onChange={(e) => {
                const { value } = e.target;
                let newValue = value;
                fillLogin('birthdate', newValue);
              }}
            />
          </Col>
        </Row>
        <Row className='mt-3 justify-content-center'>
          <Col className='d-flex flex-row gap-2 justify-content-center'>
            <button
              className='btn-submit'
              onClick={handleSubmit}
            >
              {loading ? (
                <img
                  style={{
                    width: '24px',
                  }}
                  src={Loading}
                  alt='Efetuando login, aguarde'
                />
              ) : (
                <>{`Entrar`}</>
              )}
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  const employee = (
    <Card className='card-cart'>
      <CardBody className='justify-content-center'>
        <Row className='mt-2 justify-content-center'>
          <Col md={6}>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`home-email`}
            >
              {t('login.email')}*
            </label>
            <input
              id={`home-email`}
              className='form-control'
              autoComplete='off'
              value={login.email}
              onChange={(e) => {
                const { value } = e.target;
                let newValue = emailMask(value);
                fillLogin('email', newValue);
              }}
            />
          </Col>
        </Row>
        <Row className='mt-3 justify-content-center'>
          <Col className='d-flex flex-row gap-2 justify-content-center'>
            <button
              className='btn-submit'
              onClick={handleSubmit}
            >
              {loading ? (
                <img
                  style={{
                    width: '24px',
                  }}
                  src={Loading}
                  alt='Efetuando login, aguarde'
                />
              ) : (
                <>{`Entrar`}</>
              )}
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  const choice = {
    '': choices,
    'F': franchisee,
    'E': employee,
  };

  return (
    <section className='section-cart'>
      <Modal
        open={open}
        closeModal={closeModal}
      >
        {message}
      </Modal>
      <Header />
      <form autoComplete='off'>{choice[login.type]}</form>
    </section>
  );
}
