import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useTranslation } from 'react-i18next';
export const Attendee = ({
  index,
  attendee,
  fillAttendee,
  length,
  type,
  online,
}) => {
  const [t] = useTranslation();

  const essenceclub = t('payment.attendee.essenceclub.options', {
    returnObjects: true,
  });

  const regional = t('payment.attendee.regional.options', {
    returnObjects: true,
  });

  const genders = t('payment.attendee.gender.options', {
    returnObjects: true,
  });

  const vps = t('payment.attendee.vp.options', {
    returnObjects: true,
  });

  const walkin = t('payment.attendee.walkin.options', {
    returnObjects: true,
  });

  const states = t('payment.attendee.state.options', {
    returnObjects: true,
  });

  const {
    alphabetMask,
    documentMask,
    emailMask,
    onDelete,
    mobileMask,
    zipCodeMask,
  } = useMask();

  const [otherGender, setOtherGender] = useState(false);

  const franchiseeOnline = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.title.information')}{' '}
        {length === 1 ? '' : `${index + 1}º`}{' '}
        {t('payment.attendee.title.attendee')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-name`}
          >
            {t('payment.attendee.name')}*
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-email`}
          >
            {t('payment.attendee.emailonline')}*
          </label>
          <input
            id={`attendee-${index}-email`}
            className='form-control'
            autoComplete='off'
            value={attendee.email}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-mobile`}
          >
            {t('payment.attendee.mobile')}*
          </label>
          <input
            id={`attendee-${index}-mobile`}
            className='form-control'
            autoComplete='off'
            value={attendee.mobile}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = mobileMask(value);
              fillAttendee('mobile', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.mobile);
              fillAttendee('mobile', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-address`}
          >
            {t('payment.attendee.address')}*
          </label>
          <input
            id={`attendee-${index}-address`}
            className='form-control'
            autoComplete='off'
            value={attendee.address}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('address', newValue, index);
            }}
          />
          <span className='badge text-dark'>
            Importante: colocar o endereço do CP
          </span>
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-zipcode`}
          >
            {t('payment.attendee.zipcode')}*
          </label>
          <input
            id={`attendee-${index}-zipcode`}
            className='form-control'
            autoComplete='off'
            value={attendee.zipcode}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = zipCodeMask(value);
              fillAttendee('zipcode', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.zipcode);
              fillAttendee('zipcode', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-essenceclub`}
          >
            {t('payment.attendee.essenceclub.name')}*
          </label>
          <select
            id={`attendee-${index}-essenceclub`}
            className='form-control select-style'
            autoComplete='off'
            disabled={true}
            value={attendee.essenceclub}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('essenceclub', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {essenceclub.map((essenceclub, index) => (
              <option
                key={index}
                value={essenceclub.code}
              >
                {essenceclub.name}
              </option>
            ))}
          </select>
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-cp`}
          >
            {t('payment.attendee.cp.name')}*
          </label>
          <input
            id={`attendee-${index}-cp`}
            className='form-control'
            autoComplete='off'
            value={attendee.cp}
            disabled={true}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('cp', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-regional`}
          >
            {t('payment.attendee.regional.name')}*
          </label>
          <select
            id={`attendee-${index}-regional`}
            className='form-control select-style'
            autoComplete='off'
            disabled={true}
            value={attendee.regional}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('regional', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {regional.map((regional, index) => (
              <option
                key={index}
                value={regional.code}
              >
                {regional.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
    </>
  );
  const franchisee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.title.information')}{' '}
        {length === 1 ? '' : `${index + 1}º`}{' '}
        {t('payment.attendee.title.attendee')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-name`}
          >
            {t('payment.attendee.name')}*
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-lastname`}
          >
            {t('payment.attendee.lastname')}*
          </label>
          <input
            id={`attendee-${index}-lastname`}
            className='form-control'
            autoComplete='off'
            value={attendee.lastname}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('lastname', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-namebadge`}
          >
            {t('payment.attendee.namebadge')}*
          </label>
          <input
            id={`attendee-${index}-namebadge`}
            className='form-control'
            autoComplete='off'
            maxLength={20}
            value={attendee.namebadge}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = alphabetMask(value?.toUpperCase());
              fillAttendee('namebadge', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-document`}
          >
            {t('payment.attendee.document')}*
          </label>
          <input
            id={`attendee-${index}-document`}
            className='form-control'
            autoComplete='off'
            value={attendee.document}
            maxLength={14}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = documentMask(value);
              fillAttendee('document', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-rg`}
          >
            {t('payment.attendee.rg')}*
          </label>
          <input
            id={`attendee-${index}-rg`}
            className='form-control'
            autoComplete='off'
            maxLength={20}
            value={attendee.rg}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('rg', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-birthdate`}
          >
            {t('payment.attendee.birthdate')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-birthdate`}
            min='1923-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.birthdate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('birthdate', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        {otherGender ? (
          <Col>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`attendee-${index}-gender`}
            >
              {t('payment.attendee.gender.name')}*
            </label>
            <input
              id={`attendee-${index}-gender`}
              className='form-control'
              autoComplete='off'
              value={attendee.gender}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value;
                fillAttendee('gender', newValue, index);
              }}
            />
          </Col>
        ) : (
          <Col>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`attendee-${index}-gender`}
            >
              {t('payment.attendee.gender.name')}*
            </label>
            <select
              id={`attendee-${index}-gender`}
              className='form-control select-style'
              autoComplete='off'
              value={attendee.gender}
              onChange={(e) => {
                const { value } = e.target;
                if (value === 'O') {
                  setOtherGender(true);
                  fillAttendee('gender', '', index);
                } else {
                  fillAttendee('gender', value, index);
                }
              }}
            >
              <option
                key={-1}
                value={''}
              >
                Selecione
              </option>
              {genders.map((gender, index) => (
                <option
                  key={index}
                  value={gender.code}
                >
                  {gender.name}
                </option>
              ))}
            </select>
          </Col>
        )}
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-email`}
          >
            {t('payment.attendee.email')}*
          </label>
          <input
            id={`attendee-${index}-email`}
            className='form-control'
            autoComplete='off'
            value={attendee.email}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-emailconfirmation`}
          >
            {t('payment.attendee.emailconfirmation')}*
          </label>
          <input
            id={`attendee-${index}-emailconfirmation`}
            className='form-control'
            autoComplete='off'
            value={attendee.emailconfirmation}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = emailMask(value);
              fillAttendee('emailconfirmation', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-mobile`}
          >
            {t('payment.attendee.mobile')}*
          </label>
          <input
            id={`attendee-${index}-mobile`}
            className='form-control'
            autoComplete='off'
            value={attendee.mobile}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = mobileMask(value);
              fillAttendee('mobile', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.mobile);
              fillAttendee('mobile', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-address`}
          >
            {t('payment.attendee.address')}*
          </label>
          <input
            id={`attendee-${index}-address`}
            className='form-control'
            autoComplete='off'
            value={attendee.address}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('address', newValue, index);
            }}
          />
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-number`}
          >
            {t('payment.attendee.number')}*
          </label>
          <input
            id={`attendee-${index}-number`}
            className='form-control'
            autoComplete='off'
            value={attendee.number}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('number', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-addressline2`}
          >
            {t('payment.attendee.addressline2')}*
          </label>
          <input
            id={`attendee-${index}-addressline2`}
            className='form-control'
            autoComplete='off'
            value={attendee.addressline2}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('addressline2', newValue, index);
            }}
          />
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-district`}
          >
            {t('payment.attendee.district')}*
          </label>
          <input
            id={`attendee-${index}-district`}
            className='form-control'
            autoComplete='off'
            value={attendee.district}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('district', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-zipcode`}
          >
            {t('payment.attendee.zipcode')}*
          </label>
          <input
            id={`attendee-${index}-zipcode`}
            className='form-control'
            autoComplete='off'
            value={attendee.zipcode}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = zipCodeMask(value);
              fillAttendee('zipcode', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.zipcode);
              fillAttendee('zipcode', newValue, index);
            }}
          />
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-city`}
          >
            {t('payment.attendee.city')}*
          </label>
          <input
            id={`attendee-${index}-city`}
            className='form-control'
            autoComplete='off'
            value={attendee.city}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('city', newValue, index);
            }}
          />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-state`}
          >
            {t('payment.attendee.state.name')}
          </label>
          <select
            id={`attendee-${index}-state`}
            className='form-control'
            autoComplete='off'
            value={attendee.state}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('state', newValue, index);
            }}
          >
            {states.map((state, index) => (
              <option
                key={index}
                value={state.code}
              >
                {state.name}
              </option>
            ))}
          </select>
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-cpcode`}
          >
            {t('payment.attendee.cpcode')}*
          </label>
          <input
            id={`attendee-${index}-cpcode`}
            className='form-control'
            autoComplete='off'
            value={attendee.cpcode}
            disabled={true}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('cpcode', newValue, index);
            }}
          />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-essenceclub`}
          >
            {t('payment.attendee.essenceclub.name')}*
          </label>
          <select
            id={`attendee-${index}-essenceclub`}
            className='form-control select-style'
            autoComplete='off'
            disabled={true}
            value={attendee.essenceclub}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('essenceclub', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {essenceclub.map((essenceclub, index) => (
              <option
                key={index}
                value={essenceclub.code}
              >
                {essenceclub.name}
              </option>
            ))}
          </select>
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-cp`}
          >
            {t('payment.attendee.cp.name')}*
          </label>
          <input
            id={`attendee-${index}-cp`}
            className='form-control'
            autoComplete='off'
            value={attendee.cp}
            disabled={true}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('cp', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-regional`}
          >
            {t('payment.attendee.regional.name')}*
          </label>
          <select
            id={`attendee-${index}-regional`}
            className='form-control select-style'
            autoComplete='off'
            disabled={true}
            value={attendee.regional}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('regional', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {regional.map((regional, index) => (
              <option
                key={index}
                value={regional.code}
              >
                {regional.name}
              </option>
            ))}
          </select>
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-cp`}
          >
            Classificação*
          </label>
          <input
            id={`attendee-${index}-rating`}
            className='form-control'
            autoComplete='off'
            value={attendee.rating}
            disabled={true}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('rating', newValue, index);
            }}
          />
        </Col>
      </Row>
    </>
  );

  const employee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.title.information')}{' '}
        {length === 1 ? '' : `${index + 1}º`}{' '}
        {t('payment.attendee.title.attendee')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-name`}
          >
            {t('payment.attendee.name')}*
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-lastname`}
          >
            {t('payment.attendee.lastname')}*
          </label>
          <input
            id={`attendee-${index}-lastname`}
            className='form-control'
            autoComplete='off'
            disabled={true}
            value={attendee.lastname}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('lastname', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-namebadge`}
          >
            {t('payment.attendee.namebadge')}*
          </label>
          <input
            id={`attendee-${index}-namebadge`}
            className='form-control'
            autoComplete='off'
            maxLength={20}
            value={attendee.namebadge}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = alphabetMask(value?.toUpperCase());
              fillAttendee('namebadge', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-role`}
          >
            {t('payment.attendee.role')}*
          </label>
          <input
            id={`attendee-${index}-role`}
            className='form-control'
            autoComplete='off'
            maxLength={20}
            value={attendee.role}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('role', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-department`}
          >
            {t('payment.attendee.department')}*
          </label>
          <input
            id={`attendee-${index}-department`}
            className='form-control'
            autoComplete='off'
            maxLength={20}
            value={attendee.department}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('department', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-vp`}
          >
            {t('payment.attendee.vp.name')}*
          </label>
          <select
            id={`attendee-${index}-vp`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.vp}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('vp', value, index);
            }}
          >
            {vps.map((vp, index) => (
              <option
                key={index}
                value={vp.code}
              >
                {vp.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-document`}
          >
            {t('payment.attendee.document')}*
          </label>
          <input
            id={`attendee-${index}-document`}
            className='form-control'
            autoComplete='off'
            value={attendee.document}
            maxLength={14}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = documentMask(value);
              fillAttendee('document', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-rg`}
          >
            {t('payment.attendee.rg')}*
          </label>
          <input
            id={`attendee-${index}-rg`}
            className='form-control'
            autoComplete='off'
            maxLength={20}
            value={attendee.rg}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('rg', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-birthdate`}
          >
            {t('payment.attendee.birthdate')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-birthdate`}
            min='1923-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            value={attendee.birthdate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('birthdate', newValue, index);
            }}
          />
        </Col>
        {/* <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-gender`}
          >
            {t('payment.attendee.gender.name')}*
          </label>
          <select
            id={`attendee-${index}-gender`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.gender}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('gender', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {genders.map((gender, index) => (
              <option
                key={index}
                value={gender.code}
              >
                {gender.name}
              </option>
            ))}
          </select>
        </Col> */}
        {otherGender ? (
          <Col>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`attendee-${index}-gender`}
            >
              {t('payment.attendee.gender.name')}*
            </label>
            <input
              id={`attendee-${index}-gender`}
              className='form-control'
              autoComplete='off'
              value={attendee.gender}
              onChange={(e) => {
                const { value } = e.target;
                const newValue = value;
                fillAttendee('gender', newValue, index);
              }}
            />
          </Col>
        ) : (
          <Col>
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`attendee-${index}-gender`}
            >
              {t('payment.attendee.gender.name')}*
            </label>
            <select
              id={`attendee-${index}-gender`}
              className='form-control select-style'
              autoComplete='off'
              value={attendee.gender}
              onChange={(e) => {
                const { value } = e.target;
                if (value === 'O') {
                  setOtherGender(true);
                  fillAttendee('gender', '', index);
                } else {
                  fillAttendee('gender', value, index);
                }
              }}
            >
              <option
                key={-1}
                value={''}
              >
                Selecione
              </option>
              {genders.map((gender, index) => (
                <option
                  key={index}
                  value={gender.code}
                >
                  {gender.name}
                </option>
              ))}
            </select>
          </Col>
        )}
      </Row>

      <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-email`}
          >
            {t('payment.attendee.email')}*
          </label>
          <input
            id={`attendee-${index}-email`}
            className='form-control'
            autoComplete='off'
            value={attendee.email}
            disabled={true}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
          />
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-mobile`}
          >
            {t('payment.attendee.mobile')}*
          </label>
          <input
            id={`attendee-${index}-mobile`}
            className='form-control'
            autoComplete='off'
            value={attendee.mobile}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = mobileMask(value);
              fillAttendee('mobile', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.mobile);
              fillAttendee('mobile', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          hidden={true}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-emailconfirmation`}
          >
            {t('payment.attendee.emailconfirmation')}*
          </label>
          <input
            id={`attendee-${index}-emailconfirmation`}
            className='form-control'
            autoComplete='off'
            value={attendee.emailconfirmation}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = emailMask(value);
              fillAttendee('emailconfirmation', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row
        className='mt-4'
        hidden={online === false}
      >
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
          >
            {t('payment.attendee.walkin.name')}*
          </label>

          <div
            key={index}
            className='form-check'
          >
            <input
              id={`attendee-${index}-walkin`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.d20}
              onChange={(e) => {
                fillAttendee('d20', !attendee.d20, index);
                fillAttendee('d8', false, index);
              }}
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.d20')}
            </label>
          </div>
          <div
            key={index}
            className='form-check'
          >
            <input
              id={`attendee-${index}-d21`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.d21}
              onChange={(e) => {
                fillAttendee('d21', !attendee.d21, index);
                fillAttendee('d8', false, index);
              }}
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.d21')}
            </label>
          </div>
          <div
            key={index}
            className='form-check'
          >
            <input
              id={`attendee-${index}-d22`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.d22}
              onChange={(e) => {
                fillAttendee('d22', !attendee.d22, index);
                fillAttendee('d8', false, index);
              }}
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.d22')}
            </label>
          </div>
          <div
            key={index}
            className='form-check'
          >
            <input
              id={`attendee-${index}-d8`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.d8}
              onChange={(e) => {
                fillAttendee('d8', !attendee.d8, index);
                fillAttendee('d20', false, index);
                fillAttendee('d21', false, index);
                fillAttendee('d22', false, index);
              }}
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.d8')}
            </label>
          </div>
          {/* {walkin.map((day, index) => (
            <div
              key={index}
              className='form-check'
            >
              <input
                id={`attendee-${index}-${day.code}`}
                className='form-check-input'
                type='checkbox'
                checked={attendee[day.code]}
                onChange={(e) => {
                  fillAttendee(day.code, !attendee[day.code], index);
                }}
              ></input>
              <label className='form-check-label'>{day.name}</label>
            </div>
          ))} */}
        </Col>
      </Row>
    </>
  );

  return type === 'F' ? (online ? franchiseeOnline : franchisee) : employee;
};
