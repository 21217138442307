import { Row, Col } from 'reactstrap';
import { useContext } from 'react';
import { useMask } from '../../hooks/useMask';
import { usePaymentOption } from '../../hooks/usePaymentOption';
import { useTranslation } from 'react-i18next';
import { TransactionContext } from '../../contexts/TransactionContext';

export const Customer = ({ customer, fillCustomer, free, paymentType }) => {
  const { login } = useContext(TransactionContext);
  const { alphabetMask, emailMask, documentMask, alphanumMask, onDelete } =
    useMask();
  const [t] = useTranslation();

  return (
    <>
      <h3
        className='title2'
        style={{ marginTop: 20 }}
      >
        Dados {free ? 'de envio do comprovante de inscrição' : 'de cobrança'}
      </h3>
      <Row className='mt-2'>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-name`}
          >
            {t('payment.customer.name')}*
          </label>
          <input
            id={`customer-name`}
            value={customer.name}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillCustomer('name', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-email`}
          >
            {t('payment.customer.email')}*
          </label>
          <input
            id={`customer-email`}
            value={customer.email}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillCustomer('email', newValue);
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-document`}
          >
            {t('payment.customer.document')}*
          </label>
          <input
            id={`customer-document`}
            maxLength={18}
            value={customer.document}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = documentMask(value);
              fillCustomer('document', newValue);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, customer.document);
              fillCustomer('document', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-address`}
          >
            {t('payment.customer.address')}*
          </label>
          <input
            id={`customer-address`}
            value={customer.address}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphanumMask(value);
              fillCustomer('address', newValue);
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
    </>
  );
};
