import React, { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export const Modal = ({ open, closeModal, children }) => {
  return (
    <>
      {open && (
        <SweetAlert
          title=''
          onConfirm={(e) => {}}
          customClass='my-alert'
          customButtons={
            <React.Fragment>
              <button
                className='btn btn-submit'
                style={{ borderRadius: '50%', width: '28px', height: '28px' }}
                onClick={closeModal}
              >
                OK
              </button>
            </React.Fragment>
          }
        >
          {children}
        </SweetAlert>
      )}
    </>
  );
};
