import React from 'react';
import './styled.css';

import Logo from '../../assets/images/Logo.webp';
import Banner from '../../assets/images/banner.webp';

export default function Header() {
  return (
    <div className='div-header'>
      {/* <div className='header-logo'>
        <img
          alt='Logo do evento'
          src={Logo}
          className='img-logo'
        />
      </div> */}
      <div className='div-banner'>
        <img
          alt='Banner do evento'
          src={Banner}
          className='header-img'
        />
      </div>
    </div>
  );
}
