import React, { createContext, useState, useEffect } from 'react';

export const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const productDefault = {
    id: '',
    description: '',
    price: 0,
    pixPrice: 0,
    bankslipPrice: 0,
    quantityAvailable: 0,
    description2: '',
    order: 0,
    isHalfPrice: false,
    halfPrice: 0,
    salesStartAt: '',
    salesEndAt: '',
    purchaseMin: 0,
    purchaseMax: 0,
    tag: '',
    installments: 1,
    online: false,
    i02xPrice: 0,
    i03xPrice: 0,
    i04xPrice: 0,
    i05xPrice: 0,
    i06xPrice: 0,
    i07xPrice: 0,
    i08xPrice: 0,
    i09xPrice: 0,
    i10xPrice: 0,
    i11xPrice: 0,
    i12xPrice: 0,
  };

  const loginDefault = {
    eventId: process.env.REACT_APP_EVENT_ID,
    type: '',
    cp: '',
    document: '',
    birthdate: '',
    email: '',
    recaptcha: '',
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [remainingTime, setRemainingTime] = useState(600);
  const transactionResponseDefault = { success: false, data: null };
  const [selectedProduct, setSelectedProduct] = useState(productDefault);
  const [products, setProducts] = useState([]);
  const [transactionResponse, setTransactionResponse] = useState(
    transactionResponseDefault,
  );
  const [paymentType, setPaymentType] = useState(null);

  const [login, setLogin] = useState(loginDefault);

  const fillLogin = (name, value) =>
    setLogin((prevState) => ({ ...prevState, [name]: value }));

  const [step, setStep] = useState(1);

  const firstStep = () => {
    setStep(1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const transactionContextValue = {
    productDefault,
    selectedProduct,
    setSelectedProduct,
    transactionResponse,
    setTransactionResponse,
    paymentType,
    setPaymentType,
    remainingTime,
    setRemainingTime,
    login,
    fillLogin,
    setLogin,
    step,
    firstStep,
    nextStep,
    prevStep,
    products,
    setProducts,
  };

  return (
    <TransactionContext.Provider value={transactionContextValue}>
      {children}
    </TransactionContext.Provider>
  );
};
