import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useTranslation } from 'react-i18next';
export const Flight = ({ index, attendee, fillAttendee, length, type }) => {
  const [t] = useTranslation();

  const issued = t('payment.attendee.flight.issued.options', {
    returnObjects: true,
  });

  const logisticsF = t('payment.attendee.flight.logisticsF.options', {
    returnObjects: true,
  });

  const logisticsE = t('payment.attendee.flight.logisticsE.options', {
    returnObjects: true,
  });

  const logisticsOptions = {
    F: logisticsF,
    E: logisticsE,
  };
  const logisticsArray = logisticsOptions[type];
  const transferE = t('payment.attendee.flight.transferE.options', {
    returnObjects: true,
  });

  const transferF = t('payment.attendee.flight.transferF.options', {
    returnObjects: true,
  });
  const transferOption = {
    F: transferF,
    E: transferE,
  };
  const transfersArray = transferOption[type];

  const { alphabetMask, alphanumMask } = useMask();

  const franchiseeIssued = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.flight.oneway')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightarrivaldate`}
          >
            {t('payment.attendee.flight.boardingdate')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-onewayflightarrivaldate`}
            min='2024-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightarrivaldate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('onewayflightarrivaldate', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightairline`}
          >
            {t('payment.attendee.flight.airline')}*
          </label>
          <input
            id={`attendee-${index}-onewayflightairline`}
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightairline}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('onewayflightairline', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightnumber`}
          >
            {t('payment.attendee.flight.number')}*
          </label>
          <input
            id={`attendee-${index}-onewayflightnumber`}
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightnumber}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('onewayflightnumber', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightlocator`}
          >
            {t('payment.attendee.flight.locator')}
          </label>
          <input
            id={`attendee-${index}-onewayflightlocator`}
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightlocator}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphanumMask(value);
              fillAttendee('onewayflightlocator', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflighteticket`}
          >
            {t('payment.attendee.flight.eticket')}
          </label>
          <input
            id={`attendee-${index}-onewayflighteticket`}
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflighteticket}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphanumMask(value);
              fillAttendee('onewayflighteticket', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightfrom`}
          >
            {t('payment.attendee.flight.from')}*
          </label>
          <input
            id={`attendee-${index}-onewayflightfrom`}
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightfrom}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('onewayflightfrom', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightto`}
          >
            {t('payment.attendee.flight.to')}*
          </label>
          <input
            id={`attendee-${index}-onewayflightto`}
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightto}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('onewayflightto', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightboardingtime`}
          >
            {t('payment.attendee.flight.boardingtime')}*
          </label>
          <input
            id={`attendee-${index}-onewayflightboardingtime`}
            type='time'
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightboardingtime}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('onewayflightboardingtime', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightarrivaltime`}
          >
            {t('payment.attendee.flight.arrivaltime')}*
          </label>
          <input
            id={`attendee-${index}-onewayflightarrivaltime`}
            type='time'
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightarrivaltime}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('onewayflightarrivaltime', newValue, index);
            }}
          />
        </Col>
      </Row>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.flight.return')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightarrivaldate`}
          >
            {t('payment.attendee.flight.arrivaldate2')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-returnflightarrivaldate`}
            min='2024-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightarrivaldate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('returnflightarrivaldate', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightairline`}
          >
            {t('payment.attendee.flight.airline')}*
          </label>
          <input
            id={`attendee-${index}-returnflightairline`}
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightairline}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('returnflightairline', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightnumber`}
          >
            {t('payment.attendee.flight.number')}*
          </label>
          <input
            id={`attendee-${index}-returnflightnumber`}
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightnumber}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('returnflightnumber', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightlocator`}
          >
            {t('payment.attendee.flight.locator')}
          </label>
          <input
            id={`attendee-${index}-returnflightlocator`}
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightlocator}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphanumMask(value);
              fillAttendee('returnflightlocator', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflighteticket`}
          >
            {t('payment.attendee.flight.eticket')}
          </label>
          <input
            id={`attendee-${index}-returnflighteticket`}
            className='form-control'
            autoComplete='off'
            value={attendee.returnflighteticket}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphanumMask(value);
              fillAttendee('returnflighteticket', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightfrom`}
          >
            {t('payment.attendee.flight.from')}*
          </label>
          <input
            id={`attendee-${index}-returnflightfrom`}
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightfrom}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('returnflightfrom', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightto`}
          >
            {t('payment.attendee.flight.to')}*
          </label>
          <input
            id={`attendee-${index}-returnflightto`}
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightto}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('returnflightto', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightboardingtime`}
          >
            {t('payment.attendee.flight.boardingtime')}*
          </label>
          <input
            id={`attendee-${index}-returnflightboardingtime`}
            type='time'
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightboardingtime}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('returnflightboardingtime', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightarrivaltime`}
          >
            {t('payment.attendee.flight.arrivaltime')}*
          </label>
          <input
            id={`attendee-${index}-returnflightarrivaltime`}
            type='time'
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightarrivaltime}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('returnflightarrivaltime', newValue, index);
            }}
          />
        </Col>
      </Row>
    </>
  );
  const franchiseeIssueByGB = (
    <Row className='mt-4'>
      <p>
        Após efetuar o pagamento do pacote de hospedagem, você receberá por
        e-mail o link para a emissão do seu aéreo. Para uma melhor organização
        da sua viagem, orientamos a emissão do bilhete até{' '}
        <strong>17 de julho de 2024</strong>.
      </p>
    </Row>
  );
  const franchiseeIssueByMyself = (
    <Row className='mt-4'>
      <p>
        Neste caso, é de responsabilidade do franqueado enviar o e-ticket via
        email ou whatsapp para a agência Globalis até o dia 19/07 para que
        possamos prever o traslado entre aeroporto e hotel. Em caso de alteração
        ou antecipação do voo, o franqueado deverá avisar imediatamente a
        agência, para que seja feita a reacomodação do transporte. Caso a
        informação não seja enviada, ou atualizada, a agência fica desobrigada
        do cumprimento do serviço.
      </p>
    </Row>
  );
  const franchiseeOptions = {
    'Já emiti meu bilhete aéreo': franchiseeIssued,
    'Desejo emitir via Globalis': franchiseeIssueByGB,
    'Vou emitir meu bilhete aéreo por conta própria': franchiseeIssueByMyself,
  };
  const franchiseeTransfer = (
    <>
      <Row className='mt-3'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-transfer`}
          >
            Teremos dois pontos de encontro para saída, favor selecionar o ponto
            de encontro de sua preferência:
          </label>
          <select
            id={`attendee-${index}-transfer`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.transfer}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('transfer', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {transfersArray.map((transfer, index) => (
              <option
                key={index}
                value={transfer.code}
              >
                {transfer.name}
              </option>
            ))}
          </select>
          <span className='badge text-dark'>
            Obs.: Os horários serão definidos posteriormente e informados em sua
            carta voucher
          </span>
        </Col>
      </Row>
    </>
  );
  const franchiseeFlight = (
    <>
      <Row className='mt-3'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-flightissued`}
          >
            {t('payment.attendee.flight.issued.name')}*
          </label>
          <select
            id={`attendee-${index}-flightissued`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.flightissued}
            onChange={(e) => {
              const { value } = e.target;
              const flightissued = value !== 'Já emiti meu bilhete aéreo';
              if (flightissued) {
                fillAttendee('flightissued', '', index);
                fillAttendee('onewayflightarrivaldate', '', index);
                fillAttendee('onewayflightairline', '', index);
                fillAttendee('onewayflightnumber', '', index);
                fillAttendee('onewayflightlocator', '', index);
                fillAttendee('onewayflighteticket', '', index);
                fillAttendee('onewayflightfrom', '', index);
                fillAttendee('onewayflightto', '', index);
                fillAttendee('onewayflightboardingtime', '', index);
                fillAttendee('onewayflightarrivaltime', '', index);
                fillAttendee('returnflightarrivaldate', '', index);
                fillAttendee('returnflightairline', '', index);
                fillAttendee('returnflightnumber', '', index);
                fillAttendee('returnflightlocator', '', index);
                fillAttendee('returnflighteticket', '', index);
                fillAttendee('returnflightfrom', '', index);
                fillAttendee('returnflightto', '', index);
                fillAttendee('returnflightboardingtime', '', index);
                fillAttendee('returnflightarrivaltime', '', index);
              }
              fillAttendee('flightissued', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {issued.map((issued, index) => (
              <option
                key={index}
                value={issued.code}
              >
                {issued.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      {franchiseeOptions[attendee.flightissued]}
    </>
  );

  const franchiseOwnCar = <></>;
  const franchiseeLogistics = {
    'Aéreo + transfer (Aeroporto x Hotel e Hotel x Aeroporto)':
      franchiseeFlight,
    'Carro próprio': franchiseOwnCar,
    'Transfer (Apenas para franqueados de São Paulo)': franchiseeTransfer,
  };

  const franchisee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.flight.title')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-logistics`}
          ></label>
          <select
            id={`attendee-${index}-logistics`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.logistics}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('logistics', value, index);
              fillAttendee('transfer', '', index);
              fillAttendee('flightissued', '', index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {logisticsArray.map((logistics, index) => (
              <option
                key={index}
                value={logistics.code}
              >
                {logistics.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      {franchiseeLogistics[attendee.logistics]}
    </>
  );
  const employeeFlight = (
    <Row className='mt-4'>
      <p>
        Após a finalização da sua inscrição, você deverá emitir o seu aéreo no
        link disponibilizado.
      </p>
    </Row>
  );
  const employeeTransfer = (
    <>
      <Row className='mt-4'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-transfer`}
          >
            <p>
              Disponibilizamos duas opções de pontos de encontro para a sua
              saída, selecione com <strong>atenção</strong> de acordo com a sua
              preferência.
            </p>
            <p>
              <strong>
                <i>O local selecionado não poderá ser alterado.</i>
              </strong>
            </p>
          </label>
          <select
            id={`attendee-${index}-transfer`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.transfer}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('transfer', value, index);
            }}
          >
            <option
              key={-1}
              value={''}
            >
              Selecione
            </option>
            {transfersArray.map((transfer, index) => (
              <option
                key={index}
                value={transfer.code}
              >
                {transfer.name}
              </option>
            ))}
          </select>
          {attendee.transfer === 'Vila Olimpia' && (
            <span className='badge text-dark'>
              O horário será informado posteriormente
            </span>
          )}
        </Col>
      </Row>
    </>
  );
  const employeeLogistics = (
    <Row className='mt-2'>
      <Col>
        <label
          className='form-label'
          style={{ paddingLeft: 0 }}
          htmlFor={`attendee-${index}-logistics`}
        ></label>
        <select
          id={`attendee-${index}-logistics`}
          className='form-control select-style'
          autoComplete='off'
          value={attendee.logistics}
          onChange={(e) => {
            const { value } = e.target;
            fillAttendee('logistics', value, index);
            fillAttendee('transfer', '', index);
            fillAttendee('flightissued', '', index);
          }}
        >
          <option
            key={-1}
            value={''}
          >
            Selecione
          </option>
          {logisticsArray.map((logistics, index) => (
            <option
              key={index}
              value={logistics.code}
            >
              {logistics.name}
            </option>
          ))}
        </select>
      </Col>
    </Row>
  );
  const employeeOptions = {
    'Aéreo + transfer (Aeroporto x Hotel e Hotel x Aeroporto)': employeeFlight,
    'Transfer (Apenas para Colaboradores de São Paulo)': employeeTransfer,
    'Carro Executivo': (
      <>
        <Row className='mt-3'>
          <Col>
            <p>
              A contratação do carro executivo é feita pela agência de logística
              e o custo é enviado para a área solicitante.
            </p>
          </Col>
        </Row>
      </>
    ),
    'Carro próprio': (
      <>
        <Row className='mt-3'>
          <Col>
            <p>O pagamento do estacionamento é feito pela área solicitante.</p>
            <p>
              <strong>Pavilhão da Bienal:</strong> R$ 27 (diária)
            </p>
            <p>
              <strong>Hilton Morumbi</strong>
            </p>
            <p>
              Para hóspedes e participantes de eventos, o valor é de R$ 75
              (diária). Para passantes, o valor é de R$ 90 (diária).
            </p>
            <p>
              <strong>Grand Hyatt</strong>
            </p>
            <p>
              Para hóspedes e participantes de eventos, o valor é de R$ 60
              (diária). Para passantes, o valor é de R$ 104 (diária).
            </p>
          </Col>
        </Row>
      </>
    ),
  };
  const employee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.flight.title')}
      </h3>
      {employeeLogistics}
      {employeeOptions[attendee.logistics]}
      {/* <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.flight.oneway')}
      </h3>
      <Row className='mt-2'>
        <Col md={4}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightarrivaldate`}
          >
            {t('payment.attendee.flight.boardingdate')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-onewayflightarrivaldate`}
            min='2024-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightarrivaldate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('onewayflightarrivaldate', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-onewayflightfrom`}
          >
            {t('payment.attendee.flight.fromemployee')}*
          </label>
          <input
            id={`attendee-${index}-onewayflightfrom`}
            className='form-control'
            autoComplete='off'
            value={attendee.onewayflightfrom}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('onewayflightfrom', newValue, index);
            }}
          />
        </Col>
      </Row>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.flight.return')}
      </h3>
      <Row className='mt-2'>
        <Col md={4}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightarrivaldate`}
          >
            {t('payment.attendee.flight.arrivaldate2')}*
          </label>
          <input
            type='date'
            id={`attendee-${index}-returnflightarrivaldate`}
            min='2024-01-01'
            max='2024-12-31'
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightarrivaldate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('returnflightarrivaldate', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-returnflightto`}
          >
            {t('payment.attendee.flight.toemployee')}*
          </label>
          <input
            id={`attendee-${index}-returnflightto`}
            className='form-control'
            autoComplete='off'
            value={attendee.returnflightto}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('returnflightto', newValue, index);
            }}
          />
        </Col>
      </Row> */}
    </>
  );

  useEffect(() => {}, [attendee.flightissued, attendee.logistics]);
  return type === 'F' ? franchisee : employee;
};
