import './styled.css';
import { Location } from '../location';
import { useTranslation } from 'react-i18next';

export const Information = ({ type }) => {
  const [t] = useTranslation();
  const franchisee = (
    <>
      <Location />
      <section className='content-wrapper description'>
        <h2 className='title2'>{t('cart.description.title')}</h2>
        <p>
          * A confirmação do hotel incluso no pacote será feita na última etapa
          de validação da sua inscrição. Os CPs foram alocados em cada um deles
          previamente, de acordo com as agendas segmentadas previstas no evento.
          O hotel habilitado para o seu pacote será confirmado pela agência no
          fechamento da sua inscrição.
        </p>

        <p>
          Hotel Hilton Morumbi - franqueados Diamante e com operação VCF recente
        </p>
        <p>
          Grand Hyatt - agendas segmentadas Patchouli e VDF madura e demais
          franqueados
        </p>

        <ul>
          <li>
            Hospedagem Individual ou Dupla conforme opção na compra do pacote (4
            ou 5 dias, conforme segmentação, com possibilidade de extensão da
            permanência na cidade);
          </li>
          <li>
            Check-in antecipado: garantia do seu apartamento liberado na sua
            chegada dia 19/08. Para chegadas no dia 18/08 horário de check-in às
            16h00;
          </li>
          <li>
            Transporte de chegada ao hotel a partir dos aeroportos de Guarulhos
            ou Congonhas;
          </li>
          <li>
            Transporte de retorno aos aeroportos de Guarulhos ou Congonhas;
          </li>
          <li>
            Transfer do hotel para o local do evento ao longo de todos os dias e
            Convenção;
          </li>
          <li>Seguro viagem;</li>
          <li>
            Alimentação e bebida inclusos: Café da manhã no hotel da hospedagem,
            almoço no Pavilhão da Bienal e jantar com bebidas alcoólicas/não
            alcoólica ;
          </li>
          <li>Taxas administrativas.</li>
        </ul>
      </section>
    </>
  );

  const employee = (
    <>
      <Location />
      <section className='content-wrapper description'>
        <h2 className='title2'>{t('cart.description.title')}</h2>
        <strong>INGRESSO PASSANTE:</strong>

        <p>
          O Pavilhão da Bienal estará aberto a partir das 9 horas. Chegando lá,
          dirija-se ao credenciamento.
        </p>

        <p>
          O almoço será servido no próprio Pavilhão da Bienal e o valor está
          incluso na sua inscrição, assim como os coffee breaks.
        </p>

        <p>
          É possível utilizar o estacionamento do local. O valor da diária é de
          R$27,00 e o pagamento é de responsabilidade de cada participante e o
          reembolso deve ser feito pela área.
        </p>

        <p>
          <strong>INGRESSO COM HOSPEDAGEM:</strong>
        </p>

        <p>Confira o que está incluso no seu pacote:</p>

        <ul>
          <li>
            Hospedagem em quarto duplo - o hotel já foi previamente definido,
            conforme a sua atuação no evento.
          </li>
          <li>
            Transporte de chegada ao hotel a partir dos aeroportos de Guarulhos
            ou Congonhas;
          </li>
          <li>
            Transporte de retorno aos aeroportos de Congonhas ou Guarulhos;
          </li>
          <li>
            Transfer do hotel para o local do evento ao longo de todos os dias
            de Convenção;
          </li>
          <li>Seguro viagem;</li>
          <li>
            Alimentação e bebida inclusos: Café da manhã no hotel da hospedagem,
            almoço no Pavilhão da Bienal e jantar com bebidas alcoólicas/não
            alcoólica;
          </li>
        </ul>
        <p>Lembrando que os custos são pagos pela área.</p>
      </section>
    </>
  );

  const option = {
    F: franchisee,
    E: employee,
  };
  return option[type];
};
