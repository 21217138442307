import { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isPaid } from '../../utils/service/payment';
import { Row } from 'reactstrap';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useTranslation } from 'react-i18next';
export const Pix = ({ qrCode, qrCodeText, transactionId }) => {
  const [t] = useTranslation();
  const { remainingTime } = useContext(TransactionContext);
  const navigate = useNavigate();
  const defaultText = t('payment.pix.copy');
  const defaultColor = '#000000';
  const [textButton, setTextButton] = useState(defaultText);
  const [colorButton, setColorButton] = useState(defaultColor);
  const [paid, setPaid] = useState(false);
  const buttonCopyRef = useRef(null);

  useEffect(() => {
    if (paid) {
      navigate('/finalizacao');
    }
  }, [paid, navigate]);

  useEffect(() => {
    const intervalId = setInterval(fetchData, 1000 * 8);
    const timeoutId = setTimeout(
      () => clearInterval(intervalId),
      1000 * 60 * 8,
    );
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleCopy = (e) => {
    e.preventDefault();
    const tempInput = document.createElement('input');
    tempInput.value = qrCodeText;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setTextButton(t('payment.pix.copied'));
    setColorButton('#d3a172');

    setTimeout(() => {
      setTextButton(defaultText);
      setColorButton(defaultColor);
    }, 1300);
  };

  const fetchData = async () => {
    try {
      const response = await isPaid(transactionId);
      setPaid(response.success);
    } catch (error) {
      setPaid(false);
    }
  };

  const handleImageLoad = () => {
    if (buttonCopyRef.current) {
      buttonCopyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  };

  return (
    <Row className='mt-4'>
      <section className='qr-code-wrapper'>
        <h5>
          {remainingTime === 0 ? (
            <>{t('payment.pix.expired')}</>
          ) : (
            <>
              {t('payment.pix.toexpire')} {formatTime(remainingTime)}
            </>
          )}
        </h5>
        <img
          loading='lazy'
          className='qr-code'
          alt='Qr code para realizar o pagamento'
          onLoad={handleImageLoad}
          src={qrCode}
        />
        <button
          id='btn-copy-qr-code'
          style={{ backgroundColor: colorButton }}
          className='btn-submit button-response'
          onClick={handleCopy}
          ref={buttonCopyRef}
        >
          {textButton}
        </button>
      </section>
    </Row>
  );
};
