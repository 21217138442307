import { useTranslation } from 'react-i18next';
export const FreeResponse = ({ type }) => {
  const [t] = useTranslation();
  return (
    <section className='message-wrapper'>
      <h1 className='h1 mb-4'>{t('response.free.title')}</h1>
      <section className='p-wrapper'>
        {type === 'E' && (
          <>
            <p className='card-text'>
              Será um prazer ter você conosco nesse encontro!
            </p>
            <p className='card-text'>
              <a
                href='https://globalis-boticario-lck.portaldaagencia.com.br'
                target='_blank'
              >
                Clique aqui{' '}
              </a>
              para emitir o seu aéreo - se for necessário - e concluir a sua
              presença no evento.
            </p>
          </>
        )}
        <p className='card-text'>
          Você receberá um e-mail de confirmação em breve.
        </p>
      </section>
    </section>
  );
};
