import React from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useTranslation } from 'react-i18next';
export const GeneralInfo = ({
  index,
  attendee,
  fillAttendee,
  length,
  type,
  online,
}) => {
  const [t] = useTranslation();

  const { alphabetMask, cnpjMask, onDelete } = useMask();

  const franchiseeOnline = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.generalinfo.title')}
      </h3>
      <Row className='mt-4'>
        <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-generalinfoauthorization`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.generalinfoauthorization}
              onChange={(e) => {
                fillAttendee(
                  'generalinfoauthorization',
                  !attendee.generalinfoauthorization,
                  index,
                );
              }}
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.generalinfo.authorization1')}{' '}
              <a
                href={t('payment.attendee.generalinfo.authorizationurl')}
                target='_blank'
                rel='noreferrer'
              >
                {t('payment.attendee.generalinfo.authorization2')}
              </a>{' '}
              {t('payment.attendee.generalinfo.authorization3')}
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
  const franchisee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.generalinfo.title')}
      </h3>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfofoodrestriction`}
          >
            {t('payment.attendee.generalinfo.foodrestriction')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-generalinfofoodrestriction`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinfofoodrestriction}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('generalinfofoodrestriction', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinforestriction`}
          >
            {t('payment.attendee.generalinfo.restriction')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-generalinforestriction`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinforestriction}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('generalinforestriction', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfoanothernotes`}
          >
            {t('payment.attendee.generalinfo.anothernotes')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-generalinfoanothernotes`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinfoanothernotes}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('generalinfoanothernotes', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-generalinfoauthorization`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.generalinfoauthorization}
              onChange={(e) => {
                fillAttendee(
                  'generalinfoauthorization',
                  !attendee.generalinfoauthorization,
                  index,
                );
              }}
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.generalinfo.authorization1')}{' '}
              <a
                href={t('payment.attendee.generalinfo.authorizationurl')}
                target='_blank'
                rel='noreferrer'
              >
                {t('payment.attendee.generalinfo.authorization2')}
              </a>{' '}
              {t('payment.attendee.generalinfo.authorization3')}
            </label>
          </div>
        </Col>
      </Row>
    </>
  );

  const employee = (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.generalinfo.title')}
      </h3>
      <Row className='mt-3'>
        <Col>
          <p>
            Para preenchimento dos campos abaixo, orientamos a consulta de dados
            via o seu gestor.
          </p>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfocompany`}
          >
            {t('payment.attendee.generalinfo.company')}*
          </label>
          <input
            id={`attendee-${index}-generalinfocompany`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinfocompany}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('generalinfocompany', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfodocument`}
          >
            {t('payment.attendee.generalinfo.document')}*
          </label>
          <input
            id={`attendee-${index}-generalinfodocument`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinfodocument}
            maxLength={18}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = cnpjMask(value);
              fillAttendee('generalinfodocument', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.generalinfodocument);
              fillAttendee('generalinfodocument', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfocostcenter`}
          >
            {t('payment.attendee.generalinfo.costcenter')}*
          </label>
          <input
            id={`attendee-${index}-generalinfocostcenter`}
            className='form-control'
            autoComplete='off'
            maxLength={7}
            value={attendee.generalinfocostcenter}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('generalinfocostcenter', newValue, index);
            }}
          />
        </Col>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfodepartment`}
          >
            {t('payment.attendee.generalinfo.department')}*
          </label>
          <input
            id={`attendee-${index}-generalinfodepartment`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinfodepartment}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = value;
              fillAttendee('generalinfodepartment', newValue, index);
            }}
          />
          <span className='badge text-dark'>
            Caso não tenha o PEP, favor digitar 00000
          </span>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfofoodrestriction`}
          >
            {t('payment.attendee.generalinfo.foodrestriction')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-generalinfofoodrestriction`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinfofoodrestriction}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('generalinfofoodrestriction', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinforestriction`}
          >
            {t('payment.attendee.generalinfo.restriction')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-generalinforestriction`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinforestriction}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('generalinforestriction', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-generalinfoanothernotes`}
          >
            {t('payment.attendee.generalinfo.anothernotes')}
          </label>
          <textarea
            rows={3}
            id={`attendee-${index}-generalinfoanothernotes`}
            className='form-control'
            autoComplete='off'
            value={attendee.generalinfoanothernotes}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('generalinfoanothernotes', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-generalinfoauthorization`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.generalinfoauthorization}
              onChange={(e) => {
                fillAttendee(
                  'generalinfoauthorization',
                  !attendee.generalinfoauthorization,
                  index,
                );
              }}
            ></input>
            <label className='form-check-label'>
              {t('payment.attendee.generalinfo.authorization1')}{' '}
              <a
                href={t('payment.attendee.generalinfo.authorizationurl')}
                target='_blank'
                rel='noreferrer'
              >
                {t('payment.attendee.generalinfo.authorization2')}
              </a>{' '}
              {t('payment.attendee.generalinfo.authorization3')}
            </label>
          </div>
        </Col>
      </Row>
    </>
  );

  return type === 'F' ? (online ? franchiseeOnline : franchisee) : employee;
};
