import axios from 'axios';
import * as Sentry from '@sentry/react';

const isPaid = async (id) => {
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/transactions/${id}`,
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => {
        const notpaid = error?.response?.data?.success === false;
        if (!notpaid) {
          Sentry.setContext('request', { config });
          Sentry.setContext('response', error.response.data);
          Sentry.captureMessage(config.url);
        }
        resolve({ success: false, data: error });
      });
  });
};

const transactions = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/2024/boticario/transactions`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  // console.log(JSON.stringify(data));

  // return { success: true, data: {} };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve({ success: true, data: response.data }))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.data);
        Sentry.captureMessage(config.url);
        resolve({ success: false, data: error.response.data });
      });
  });
};

const signin = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/2024/boticario/login`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve({ success: true, data: response.data }))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.data);
        Sentry.captureMessage(config.url);
        resolve({ success: false, data: error.response.data });
      });
  });
};

const created = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/2024/boticario/validate`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve({ success: true, data: response.data }))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.data);
        Sentry.captureMessage(config.url);
        resolve({ success: false, data: error.response.data });
      });
  });
};

const isBlockedByAdBlock = () => {
  return new Promise(async (resolve) => {
    try {
      window.Iugu.utils.isBlockedByAdBlock((response) => {
        resolve(response);
      });
    } catch (error) {
      resolve(false);
    }
  });
};

function getTokenRecaptcha() {
  return new Promise((resolve, reject) => {
    window?.grecaptcha?.ready(function async() {
      try {
        window?.grecaptcha
          .execute('6LfLeyMkAAAAALWP3pYs1dvKfD9DSigLcgsdN3a2', {
            action: 'submit',
          })
          .then(function (token) {
            resolve(token);
          });
      } catch (error) {
        reject(error);
      }
    });
  });
}

export {
  transactions,
  signin,
  isPaid,
  isBlockedByAdBlock,
  getTokenRecaptcha,
  created,
};
